import React from 'react';


const RadioStation = () => {
    return (
        <div className="radio-station">
            <iframe src="https://tdapp.djcincinati.com/cp/widgets/player/single/?p=8022" height="110" width="100%" scrolling="no" style={{ border: "none" }}></iframe>
        </div >
    )
}

export default RadioStation;


