import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyDYtHO1H27aUkfK2GqHEqxWWB7Hmx8_hQE",
  authDomain: "iranianmanchester-315ff.firebaseapp.com",
  projectId: "iranianmanchester-315ff",
  storageBucket: "iranianmanchester-315ff.appspot.com",
  messagingSenderId: "312022382284",
  appId: "1:312022382284:web:11daa084b065843d3eb469",
  measurementId: "G-9XQGB8ZW28"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export {app, authentication};